import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../App';
import '../assets/styles/Home.css';

const Home = () => {

    const {user} = useContext(UserContext);
    useEffect(() => {
        if (user) {
            window.location.href="/generate"
        }
    }, [user])
  
    const goToSignup = () => {
        window.location.href='/signup';
    }

    const goToAbout = () => {
        window.location.href = '/about'
    }

    return (
        <div className="main-container">
            <div className="header">
                <h1 className="page-title">Counter It: Leveraging <span style={{"color": "blue", "fontWeight":"bolder"}}>AI</span> to fight political <span style={{"color": "red", "fontWeight":"bolder"}}>polarization</span></h1>
            </div>
            <main className="main-content">
                <section className="intro-section">
                    <p>Counter It is a platform that is designed to challenge our views. Taking advantage of advances in generative technology, we have redefined the way that you learn news. Counter It takes in existing media and generates an alternative perspective to the same issue, enabling users to challenge their views and understand more perspectives. Moreover, using conversational engines, users can talk to chatbots that represent various political perspectives and debate in safe and healthy ways.</p>
                    <p>Submit an article, read an alternative, and talk to our chatbots in a safe and healthy way! </p>
                </section>
                <div className="buttons">
                    <button className="cta-button" onClick={goToSignup}>Try it for Free</button>
                    <button className="cta-button" onClick={goToAbout}>Learn More</button>
                </div>
            </main>
        </div>
    );
};

export default Home;
