import React, { useState } from "react";
import "../assets/styles/About.css"

const About = () => {
    return (
    <>
    <header>
        <h1 className="page-title">Counter It: Leveraging AI to fight political polarization</h1>
    </header>
    <main>
        <section>
            <h2>Our Mission</h2>
            <p>
                We are on a mission to empower individuals to make informed decisions by gaining a deeper understanding of the political bias prevalent in news articles. We strive to bridge the gap between different political perspectives, encouraging open-mindedness, critical thinking, and respectful dialogue.
            </p>
        </section>
        
        <section>
            <h2>How It Works</h2>
            <p>
                CounterIt allows you to upload news articles from various sources. Our advanced natural language processing algorithms analyze the articles and provide an objective assessment of their political bias. By understanding the political leaning of a piece, users can gain valuable insights into how different viewpoints shape the news.
            </p>            
            <p>
                Moreover, our generative AI technology transforms the original article into an alternative version from a different political perspective. This feature enables users to explore how a particular news story might be presented from various ideological standpoints, fostering empathy and expanding their worldview.
            </p>
        </section>
        
        <section>
            <h2>Real-time Chat Bot</h2>
            <p>
                We understand the importance of discussion and community engagement. That's why we offer a real-time chat bot where users can engage in meaningful conversations about the uploaded articles. Our chat feature provides a platform to exchange ideas, share perspectives, and challenge one another's viewpoints in a respectful manner.
            </p>
        </section>
        
        <section>
            <h2>Your Voice Matters</h2>
            <p>
                We value the feedback and input of our users. Your contributions help us improve the accuracy of our political bias analysis and enhance the quality of our generative AI transformations. Together, we can build a platform that empowers individuals to navigate the complex landscape of news media.
            </p>
        </section>
        
        <section>
            <h2>Join the CounterIt Community</h2>
            <p>
                We invite you to join our growing community of individuals who are passionate about promoting media literacy and understanding political bias. Together, let's counter the biases and uncover the multifaceted nature of news articles.
            </p>
            <p>
                Start your journey with CounterIt today and gain a deeper understanding of the political bias in news media.
            </p>
        </section>
    </main>
    </>
  );
};

export default About;
