import React, { useState, useEffect, useContext } from 'react';
import styles from '../assets/styles/ArticlePage.module.css';
import { useParams } from 'react-router-dom';
import { UserContext } from '../App';
import CommentsSection from '../components/CommentsSection';

const ArticlePage = () => {
  const [data, setData] = useState(null);
  const { username, domain } = useParams();
  const { user } = useContext(UserContext);

  useEffect(() => {
    fetch(`https://counterit-backend.onrender.com/article/${username}.${domain}`)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        console.log(user)
        setData(data);
      });
  }, [user, username, domain]);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={styles.article}>
        <h1 className={styles.title}>{data.article.article.title}</h1>
        <p className={styles.content}>{data.article.article.content}</p>
      </div>
      <CommentsSection articleId={`${username}.${domain}`} user={user} />
    </>
  );
};

export default ArticlePage;
