import React, { useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import ChatUI from "../components/ChatBot";
import styles from '../assets/styles/Generator.module.css';
import { UserContext } from '../App';

const Generator = () => {
  const [articleText, setArticleText] = useState("");
  const [showChatbot, setShowChatbot] = useState(false)
  const [results, setResults] = useState({"bias": 0, "article": {"title": "AI Generated Article", "subtitle": "", "date": "", "references": [], "content": "Upload an article and CounterIt will write a similar new article that gives you the opposite end of the political spectrum."}});
  const [currError, setCurrError] = useState(null)
  const markerRef = useRef();
  const {user} = useContext(UserContext);
  const [articleName, setArticleName] = useState(''); // State to store the article name
  const [saveError, setSaveError] = useState(''); 
  const [saveSuccess, setSaveSuccess] = useState(''); 

  useEffect(() => {
    if (user) {
        fetch("https://counterit-backend.onrender.com/details", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: user.authToken})
        }).then(response => response.json())
        .then(data => {
            let profileTier = data['profile_tier']
            let credits = data['credits']
            localStorage.setItem('profile_tier', profileTier)
            localStorage.setItem('credits', credits)

        });
    }
  }, [user])

  const saveArticle = async () => {
    if (!user) {
      alert('You must be logged in to save articles.');
      return;
    }
    console.log({
      domain_name: articleName,
      article: results,
      token: user.authToken,
    })

    if (results.article.subtitle === "") {
      setSaveError("Please generate an article first.")
      return;
    }

    if (articleName.length < 5) {
      setSaveError("Please give an article name that is atleast 5 letters long.")
      return;
    }

    try {
      const response = await axios.post('https://counterit-backend.onrender.com/add_article', {
        domain_name: articleName,
        article: results,
        given_article: articleText,
        token: user.authToken,
      });

      if (response.status === 200) {
        setSaveSuccess('Article saved successfully!');
      } else {
        setSaveError(JSON.stringify(response.data));
      }
    } catch (error) {
      console.error('Failed to save article:', error.response.data.error);
      setSaveError(error.response.data.error);
    }
  };

  const handleInputChange = (event) => {
    setArticleText(event.target.value);
  };

  const handleSubmit = async () => {
    const current = new Date();
    const currentDate = current.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
    
    try {
      setShowChatbot(false)
      setCurrError(null)
      setResults({"bias": 0, "article": {"title": "Loading...", "subtitle": "", "date": "Loading...", "references": [], "content": "Loading..."}});
      const res = await axios.post('https://counterit-backend.onrender.com/api/counter-it', 
      { 
        text: articleText, 
        token: user.authToken
      });
      console.log(res)
    
      // let references;
      // if (typeof res.data.article.references === 'string') {
      //   references = res.data.article.references.split('\n');
      // } else {
      //   references = res.data.article.references;
      // }
      // res.data.article.references = references;
      res.data.article.date = currentDate
      let content = res.data.article.content
      content = content.replace('/\n\n/g', '<br/>');
      content = content.replace('/\t/g', '&emsp;');
      res.data.article.content = content

      let bias  = Math.min(2 * res.data.bias, 42);
      setResults({"bias": bias, "article": res.data.article})

      let position = ((bias + 42) / 84) * 100;
      markerRef.current.style.left = `calc(${position}% - 5px)`;
      setShowChatbot(true)
    } catch (error) {
        if (error.response) {
          setCurrError(error.response.data.error);
        } else if (error.request) {
          setCurrError(error.request)
        } else {
          setCurrError(error.message)
        }
        console.log(error.config);
    }
  };

  return (
    <>
      {user ? 
      <>
        {/* <h1 className="title">CounterIt.org</h1> */}
        <div className={styles.container}>
          <div className={styles.left}>
            <textarea className={styles["text-input"]} onChange={handleInputChange} />
            <button className={styles["btn-submit"]} onClick={handleSubmit}>Counter It</button>
            <div>
              <div style={{
                height: '20px',
                background: 'linear-gradient(to right, darkblue, darkred)',
                position: 'relative'
              }}>
                <div ref={markerRef} style={{
                  width: '10px',
                  height: '20px',
                  left: 'calc(50% - 5px)',
                  background: 'white',
                  position: 'absolute'
                }} />
              </div>
              <p style={{
                color: results.bias > 0 ? 'red' : 'blue'
              }}>
                Your Article's Bias: {results.bias}
              </p>
            </div>    
            {showChatbot ? 
            <>
            <ChatUI article={articleText}></ChatUI>
            </> 
            : 
            <>
            </>}
          </div>
          {currError === null ? 
            <header className={styles["App-header"]}>
                <h1 className={styles["title"]}>{results.article.title}</h1>
                <h2 className={styles.subtitle}>{results.article.subtitle}</h2>
                {/* <h4 className={styles.author}>{results.article.references && "by CounterIt AI"}</h4> */}
                <h4 className={styles.author}>{"by CounterIt AI"}</h4>
                <p className={styles.date}>{results.article.date}</p>
                {results.article.content.split('\n\n').map((paragraph, index) => (
                  <p key={index} className={styles.content}>{paragraph}</p>
                ))}
                {/* {results.article.references && <h4>References</h4>}
                {results.article.references.map((ref, index) => <p className="reference"key={index}>{ref}</p>)} */}
                {results.article.subtitle !== "" && user && (
                  <>
                    <input
                      type="text"
                      placeholder="Enter article name"
                      value={articleName}
                      className={styles.saveInput}
                      onChange={e => setArticleName(e.target.value)}
                    />
                    <button className={styles.saveSubmit} onClick={saveArticle}>Save this page</button>
                    <br />
                    <span className={styles.errorMessage}>{saveError}</span>
                    <span className={styles.saveMessage}>{saveSuccess}</span>
                  </>
                )}
              </header>
              :
              <>
                {currError === "Not enough credits" ? 
                <>
                  <h1>Not Enough Credits.<br></br><br></br> <a href="/subscribe">Subscribe</a> to keep using this month.</h1>
                </> : 
                <>
                  <h1>{currError}</h1>
                </>} 

              </>
              }
        </div>
      </>
      : 
      <>
        <h2 className={styles.notSignedIn}>Please <a href="/signin">sign in</a> or <a href="/signup">sign up</a> to view page</h2>
      </>}
    </>
  );
};

export default Generator;
