import React, { useState, useContext, useEffect } from 'react';
import { parse, formatDistanceToNow, add } from 'date-fns';
import { getAuth, updateProfile } from 'firebase/auth';
import styles from '../assets/styles/Profile.module.css';
import { doc, getDoc } from "firebase/firestore";
import { UserContext } from '../App';
import axios from "axios";

function Profile() {
  const [username, setUsername] = useState();
  const [credits, setCredits] = useState(null)
  const [creditsDate, setCreditsDate] = useState(null)
  const [plan, setPlan] = useState(null)
  const [error, setError] = useState(null);
  const [goodMessage, setGoodMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const {user} = useContext(UserContext);
  const auth = getAuth();

  useEffect(() => {
    if (user) {
        const savedProfileTier = localStorage.getItem('profile_tier')
        const savedCredits = localStorage.getItem('credits')
        setPlan(savedProfileTier)
        setCredits(savedCredits)
      
        fetch("https://counterit-backend.onrender.com/details", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: user.authToken})
        }).then(response => response.json())
        .then(data => {
            let newPlan = data['profile_tier']
            let newCredits = data['credits']
            if (newPlan !== savedProfileTier) {
                setPlan(newPlan)
            }
            if (newCredits !== savedCredits) {
                setCredits(newCredits)
            }
            const timeStamp = (data['credit_set_date'])
            const date = new Date(timeStamp)
            date.setMonth(date.getMonth() + 1);

            var now = new Date();
            var differenceInMilliseconds = date - now;
            var differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
            differenceInDays = Math.floor(differenceInDays);

            setCreditsDate(differenceInDays)
        });
    }
  }, [user])

  const updateUsername = async (event) => {
    event.preventDefault();
    setError(null)
    setGoodMessage(null)
    if (username.length < 4) {
        setError("Username must be at least 4 characters long")
    }
    else if (username === user.username) {
        setError("Username has not been changed")
    }
    else {
        try {
            const response = await axios.post('https://counterit-backend.onrender.com/update', {
              token: user.authToken,
              username: username
            });
            if (response.status === 200) {
              setGoodMessage("Username Updated!")
            }
        } catch (error) {
            // Check if the error response is 409 which indicates username conflict
            if (error.response && error.response.status === 409) {
                setError("Username already exists");
            } else {
                console.error('Could not update', error);
                setError(error.message);
            }
        }
    }
  }

  return (
    <>
    {user ? <>
        <div className={styles.container}>
            <h2>My Profile</h2>
            {error && <p className={styles.error}>{error}</p>}
            {goodMessage && <p className={styles.goodMessage}>{goodMessage}</p>}
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <h4>Username: {user.username}</h4>
                    <h4>Credits: {credits}</h4>
                    <h4>Plan: {plan ? plan.charAt(0).toUpperCase() + plan.slice(1) : ""}</h4>
                    <h4>Credits Reset: {creditsDate} days</h4>
                </>
            )}
        </div>
    </> : 
    <>
    <h2 className={styles.notSignedIn}>Please <a href="/signin">sign in</a> or <a href="/signup">sign up</a> to view page</h2>
    </>}
    </>
  );
}

export default Profile;
