import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { serverTimestamp } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD8iyJHz4L1Q4ltvffJkeKLjcrtY0F_IhI",
  authDomain: "counter-it-75956.firebaseapp.com",
  projectId: "counter-it-75956",
  storageBucket: "counter-it-75956.appspot.com",
  messagingSenderId: "1023036616003",
  appId: "1:1023036616003:web:27f6d6b750aeec4ad013f6",
  measurementId: "G-XGW9D006G5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);
