import React, { useState } from "react";
import styles from "../assets/styles/404.module.css"

const PageNotFound = () => {
    return (
        <>
            <div className={styles.errorBody}>
                <h1 className={styles.errorMessage}>The page you requested cannot be found</h1>
                <h2 className={styles.errorMessage}>The URL may be misspelled or the page you're looking for is no longer available.</h2>
            </div>
        </>
    );
};

export default PageNotFound;
