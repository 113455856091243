import React, { useReducer, useEffect, createContext } from "react";
import { Route, Routes } from 'react-router-dom';
import Generator from "./pages/Generator";
import SignIn from "./pages/Signin";
import Home from "./pages/Home";
import { Suspense } from 'react';
import './firebase';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import SignUp from "./pages/Signup";
import Navbar from "./components/Navbar";
import About from "./pages/About"
import Footer from "./components/Footer";
import FAQ from "./pages/Faq"
import Contact from "./pages/Contact"
import ResetPassword from "./pages/ResetPassword"
import Profile from "./pages/Profile"
import Payment from "./pages/Payment"
import Billing from "./pages/Billing";
import PageNotFound from "./pages/404"

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import ArticlePage from "./pages/ArticlePage";

import styles from "./assets/styles/App.module.css"
import Layout from "./components/Layout";

export const UserContext = createContext();
const stripePromise = loadStripe('pk_test_51NbBubJe7EDdnKw9GbRT8FYaqR1gk7cYs60ez6dUA5YdRc7G9fZpAQief0G1hoTZlXrK5G7FJLGt44Tdr5oTObpm00iMcVOFig')

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    default:
      return state;
  }
};

function App() {
  const auth = getAuth();
  const db = getFirestore();
  const [user, dispatch] = useReducer(userReducer, null);


  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // User is signed in, get their document from Firestore
        const userDoc = await getDoc(doc(db, "users", authUser.uid));
        if (userDoc.exists()) {
          // User document found, set the user state
          // Get the auth token
          const token = await authUser.getIdToken();
          dispatch({ type: 'SET_USER', payload: { ...userDoc.data(), authToken: token } });
        } 
      } else {
        // User is signed out, clear the user state
        dispatch({ type: 'SET_USER', payload: null });
      }
    });

    // Cleanup the subscription on unmount
    return unsubscribe;
  }, [auth, db]);

  return (
    <UserContext.Provider value={{user, dispatch}}>
      <Suspense>
        <div className={styles["app-layout"]}>
            <Routes>
                <Route path="/" element={<Layout><Home/></Layout>}/>
                <Route path="/generate" element={<Layout><Generator/></Layout>} />
                <Route path="/about" element={<Layout><About/></Layout>}/>
                <Route path="/signin" element={<Layout><SignIn/></Layout>} />
                <Route path="/signup" element={<Layout><SignUp/></Layout>} />
                <Route path="/FAQ" element={<Layout><FAQ/></Layout>}/>
                <Route path="/contact" element={<Layout><Contact/></Layout>}/>
                <Route path="/reset-password" element={<Layout><ResetPassword/></Layout>}/>
                <Route path="/my-profile" element={<Layout><Profile/></Layout>}/>
                <Route path="/:username/:domain" element={<Layout><ArticlePage/></Layout>} />
                <Route path="/subscribe" element={
                <Layout>
                  <Elements stripe={stripePromise}>
                    <Payment/>
                  </Elements>
                </Layout>
                }/>
                <Route path="/billing" element={
                <Layout>
                  <Elements stripe={stripePromise}>
                    <Billing/>
                  </Elements>
                </Layout>
                }></Route>
                <Route path="*" element={<Layout><PageNotFound/></Layout>} />
            </Routes>
          </div>
      </Suspense>
    </UserContext.Provider>
  );
}

export default App;
