import React, { useEffect, useState, useContext } from 'react';
import { getAuth, getIdToken, createUserWithEmailAndPassword, deleteUser } from 'firebase/auth';
import styles from '../assets/styles/Signup.module.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../App';

function SignUp() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);

  const auth = getAuth();
  const {user} = useContext(UserContext);

  useEffect( () => {
    if (user) {
        window.location.href = "/generate";
    }
  }, [user])

  const signUp = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      getIdToken(userCredential.user).then((idToken) => {
        fetch("https://counterit-backend.onrender.com/signup", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token: idToken, username: username, email: email })
        }).then(async response => {
          const responseData = await response.json();
          if (response.status == 200) {
            dispatch({ type: 'SET_USER', payload: { username, authToken: idToken } });
            setIsLoading(false)
            navigate("/generate");
          } else {
            throw new Error(responseData.error);
          }
        })
        .catch(async (error) => {
          console.log(error);
          setError(error.message);
          setIsLoading(false)
          try {
            await deleteUser(userCredential.user);
          } catch (deleteError) {
            console.error('Failed to delete user:', deleteError);
          }
        });
      });
    } catch (error) {
      setIsLoading(false)
      if (error.code === "auth/email-already-in-use") {
        setError("Email already in use");
      } else {
        setError(error.message);
      }
    }
  }
  
  
  return (
    <div className={styles.container}>
      <h2>Sign Up</h2>
      {error && <p className={styles.error}>{error}</p>}
      {isLoading ? (
        <div className={styles.ellipsis}><div></div><div></div><div></div><div></div></div>
      ) : (
        <form onSubmit={signUp}>
          <div className={styles.signUpItem}>
              <label>
              Username:
              </label><input value={username} onChange={(e) => setUsername(e.target.value)} required />
              
          </div>
          <div className={styles.signUpItem}>
              <label>
              Email:
              </label><input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              
          </div>
          <div className={styles.signUpItem}>
              <label>
              Password:
              </label>
              <input className={styles.signUpBox}type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <button type="submit">Sign Up</button>
        </form>
      )}
      
      <p>Already have an account? <a href="/signin">Sign in</a></p>
    </div>
  );
}

export default SignUp;
