import React, { useState } from "react";
import styles from "../assets/styles/Faq.module.css";

const FAQ = () => {
  const [visibleSection, setVisibleSection] = useState(null);

  const toggleSection = (index) => {
    if (visibleSection === index) {
      setVisibleSection(null); // Hide the section if it's already visible
    } else {
      setVisibleSection(index); // Show the clicked section
    }
  };

  const faqs = [
    {
      question: "What is Counter It's mission?",
      answer:
        "Our mission is to empower individuals to make informed decisions by gaining a deeper understanding of the political bias prevalent in news articles. We strive to bridge the gap between different political perspectives, encouraging open-mindedness, critical thinking, and respectful dialogue."
    },
    {
      question: "How does Counter It work?",
      answer:
        "CounterIt allows you to upload news articles from various sources. Our advanced natural language processing algorithms analyze the articles and provide an objective assessment of their political bias. Additionally, our generative AI technology transforms the original article into an alternative version from a different political perspective."
    },
    {
      question: "What is the Real-time Chat Bot feature?",
      answer:
        "The real-time chat bot is a platform for users to engage in meaningful conversations about the uploaded articles. This feature provides a platform to exchange ideas, share perspectives, and challenge one another's viewpoints in a respectful manner."
    },
    {
      question: "How can I contribute to Counter It?",
      answer:
        "We value the feedback and input of our users. Your contributions help us improve the accuracy of our political bias analysis and enhance the quality of our generative AI transformations."
    },
    {
      question: "How can I join the Counter It community?",
      answer:
        "You can join our community by starting your journey with Counter It today. We are a group of individuals who are passionate about promoting media literacy and understanding political bias."
    }
  ];

  return (
    <div>
      <header className={styles.header}>
        <h1 className="page-title">Frequently Asked Questions</h1>
      </header>
      <main>
        {faqs.map((faq, index) => (
          <section
            key={index}
            className={styles.mainSection}
            onClick={() => toggleSection(index)}
          >
            <h2>{faq.question}</h2>
            <p
              className={styles.paragraph}
              style={{ display: visibleSection === index ? "block" : "none" }}
            >
              {faq.answer}
            </p>
          </section>
        ))}
      </main>
    </div>
  );
};

export default FAQ;
