import React from 'react';
import styles from "../assets/styles/Footer.module.css"
const Footer = () => {
    return (
        <footer className={styles.Footer}>
            <p>Copyright &copy; {new Date().getFullYear()} Counter It</p>
            <a className={styles.link} href="/about">About</a>
            <a className={styles.link} href="/generate">Generate</a>
            <a className={styles.link} href="/FAQ">FAQ</a>
            <a className={styles.link} href="/Contact">Contact</a>
            <a className={styles.link} href="/my-profile">Profile</a>
            <a className={styles.link} href="/subscribe">Subscribe</a>
        </footer>
    );
}

export default Footer;
