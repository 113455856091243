import React, { useEffect, useState, useContext } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import styles from '../assets/styles/Navbar.module.css';
import { UserContext } from '../App';

function Navbar() {
    const {user} = useContext(UserContext);
    const auth = getAuth(); // Create a new instance of auth

    const handleSignin = () => {
        window.location.href = '/signin';
    }

    const handleSignup = () => {
        window.location.href = '/signup';
    }

    const handleLogout = async () => {
        await signOut(auth);
    }

    return (
        <nav className={styles.navbar}>
            <a className={styles.navbarLink} href="/"><h2 className={styles.navbarTitle}>Counter It</h2></a>
            {user ? (
                <div>
                    <a className={styles.navbarLink} href="/my-profile">{user ? user.username : ""}</a>
                    <button className={styles.navbarButton} onClick={handleLogout}>Sign Out</button>
                </div>
            ) : (
                <div>
                    <button className={styles.navbarButton} onClick={handleSignin}>Sign In</button>
                    <button className={styles.navbarButton} onClick={handleSignup}>Sign Up</button>
                </div>
            )}
        </nav>
    );
}

export default Navbar;
