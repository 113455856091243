import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import styles from '../assets/styles/App.module.css'

const Layout = ({ children }) => {
    return (
        <>
            <Navbar />
            <div className={styles["page-wrapper"]}>
                {children}
            </div>
            <Footer />
        </>
    );
}

export default Layout;
