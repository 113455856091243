import React, { useEffect, useState } from 'react';
import styles from '../assets/styles/CommentsSection.module.css';

const CommentsSection = ({ articleId, user }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    // Fetch comments when the component mounts
    useEffect(() => {
        fetchComments();
    }, []);

    const fetchCommentsFromAPI = async (articleId) => {
        const response = await fetch(`https://counterit-backend.onrender.com/get_comments/${articleId}`, {
            method: 'GET'
        });
        const comments = await response.json();
        console.log(comments)
        return comments;
    };

    const addCommentToAPI = async (articleId, newComment) => {
        const response = await fetch('https://counterit-backend.onrender.com/add_comment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.authToken}`, // Add auth token to request headers
            },
            body: JSON.stringify({ articleId, text: newComment, token: user.authToken }),
        });
        const result = await response.json();
        return result;
    };

    const fetchComments = async () => {
        const comments = await fetchCommentsFromAPI(articleId);
        setComments(comments);
    };

    const handleAddComment = async (event) => {
        event.preventDefault();
        await addCommentToAPI(articleId, newComment);
        setNewComment('');
        fetchComments();
    };

    return (
        <div className={styles["comments-section"]}>
            {user && (
                <form className={styles["comment-form"]} onSubmit={handleAddComment}>
                    <label>
                        <input 
                            className={styles["comment-input"]}
                            type="text"
                            value={newComment}
                            placeholder="Add a comment..."
                            onChange={e => setNewComment(e.target.value)} 
                        />
                    </label>
                    <button className={styles["comment-submit"]} type="submit">Post</button>
                </form>
            )}
            
            <ul className={styles["comment-list"]}>
                {comments.map(comment => (
                    <li className={styles["comment-item"]} key={comment.id}>
                        <p className={styles["comment-text"]}>{comment.info.username} says at {comment.info.timestamp}</p>
                        <p className={styles["comment-text"]}>{comment.info.text}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
    
};

export default CommentsSection;
