import React, { useState, useEffect } from 'react';
import styles from "../assets/styles/ChatBot.module.css"

const ChatUI = (articleText) => {
    const [leftMessages, setLeftMessages] = useState([{ text: 'Learn about my point of view on the issue!', sender: 'Liberal' }]);
    const [rightMessages, setRightMessages] = useState([{ text: 'Learn about my point of view on the issue!', sender: 'Conservative' }])
    const [chatPartner, setChatPartner] = useState("Liberal")
    const [input, setInput] = useState('');
    const [chatReady, setChatReady] = useState(true)
    const [isWaiting, setIsWaiting] = useState(false)

    // useEffect(() => {
    //     setChatReady(false)
    //     sendDefault(articleText)
    // }, [articleText])

    const sendDefault = async(message) => {
        try {
            const left_response = await fetch(`http://localhost:3001/left/message?message=${encodeURIComponent(message + "\n Can we discuss this article?")}`);
            const left_data = await left_response.json();
            console.log("Left data: " + left_data)
            const right_response = await fetch(`http://localhost:3001/right/message?message=${encodeURIComponent(message + "\n Can we discuss this article?")}`);
            const right_data = await right_response.json();
            console.log("Right Data: " + right_data)
            setChatReady(true)
        } catch (err) {
            console.error(err);
        }
    }
    const sendMessageLeft = async (message) => {
        setIsWaiting(true)
        setLeftMessages(oldMessages => [
            ...oldMessages,
            { text: message, sender: 'User' },
            { text: 'Waiting for response...', sender: 'Liberal' }
        ]);
        try {
            const response = await fetch(`https://chatbot-backend-i301.onrender.com/left/message?message=${encodeURIComponent(message)}`);
            const data = await response.json();
            setLeftMessages(oldMessages => {
                let newMessages = [...oldMessages];
                newMessages.pop();  // This removes the last element from newMessages
                return [...newMessages, { text: data.message, sender: 'Liberal' }];
            });
            console.log("message returned")
        } catch (err) {
            console.error(err);
        }
        setIsWaiting(false)
    };

    const sendMessageRight = async (message) => {
        setIsWaiting(true)
        setRightMessages(oldMessages => [
            ...oldMessages,
            { text: message, sender: 'User' },
            { text: 'Waiting for response...', sender: 'Conservative' }
        ]);
        try {
            const response = await fetch(`https://chatbot-backend-i301.onrender.com/right/message?message=${encodeURIComponent(message)}`);
            const data = await response.json();
            setRightMessages(oldMessages => {
                let newMessages = [...oldMessages];
                newMessages.pop();
                return [...newMessages, { text: data.message, sender: 'Conservative' }];
            });
        } catch (err) {
            console.error(err);
        }
        setIsWaiting(false)
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        chatPartner === "Liberal" ? sendMessageLeft(input) : sendMessageRight(input)
        setInput('');
    };

    const switchChatPartner = (chatPartnerName) => {
        setChatPartner(chatPartnerName)
    };

    return (
        <div className={styles.chatbot}>
            <h1 className={styles.title} style={{"color": "#2f4f4f"}}>Talking to {chatPartner}</h1>
            <div className={styles.buttoncontainer}>
                <button className={styles.leftButton} onClick={() => switchChatPartner("Liberal")}>Liberal</button>
                <button className={styles.rightButton} onClick={() => switchChatPartner("Conservative")}>Conservative</button>
            </div>
            {chatReady ?
            <>
                {chatPartner === "Liberal" ? 
                    <>
                        {leftMessages.map((message, index) => (
                            <p key={index}><b>{message.sender}:</b> {message.text}</p>
                        ))}
                    </> 
                : 
                    <>
                        {rightMessages.map((message, index) => (
                            <p key={index}><b>{message.sender}:</b> {message.text}</p> 
                        ))} 
                    </>}
                <form className={styles.textForm}onSubmit={handleSubmit}>
                    <input className={styles.inputBox} type="text" value={input} onChange={handleInputChange} />
                    {isWaiting === false ?
                    <button className ={styles.submitButton} type="submit">Send</button> :
                    <></>
                    }
                    
                </form>
            </> : 
            <>
                <h1>Chatbot Preparing ...</h1>
            </> }

        </div>
    );
};

export default ChatUI;
