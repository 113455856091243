import React, { useState, useContext, useEffect } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {UserContext} from '../App';
import {CardElement, Elements, useStripe, useElements} from '@stripe/react-stripe-js';
import styles from '../assets/styles/Payment.module.css'

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [selectedPlan, setSelectedPlan] = useState("starter");
  const [currentPlan, setPlan] = useState(null)
  const [error, setError] = useState("")
  const [goodMessage, setGoodMessage] = useState("")
  const {user} = useContext(UserContext)

  useEffect(() => {
    if (user) {
        const savedProfileTier = localStorage.getItem('profile_tier')
        setPlan(savedProfileTier)
        console.log("Saved Tier: " + savedProfileTier)
      
        fetch("https://counterit-backend.onrender.com/details", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: user.authToken})
        }).then(response => response.json())
        .then(data => {
            let profileTier = data['profile_tier']
            if (profileTier !== savedProfileTier) {
              setPlan(profileTier)
              localStorage.setItem('profile_tier', profileTier)
            }
        });
    }
  }, [user])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("")
    setGoodMessage("")

    const userConfirmed = window.confirm("Are you sure you want to make this payment?");
    if (!userConfirmed) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.token);
      
      // You can now send the token to your backend for further processing
      fetch("https://counterit-backend.onrender.com/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({user_id: user.authToken, token: result.token.id, plan: selectedPlan}) 
      }).then(response => response.json())
      .then(data => {
        if (data.error) {
          setError(data.error)
        } else {
          setError("Payment Succeeded")
          fetch("https://counterit-backend.onrender.com/update-plan", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              plan: selectedPlan,
              token: user.authToken
            }) 
          })
        }
      });
    }
  };

  return (
    <>
      {user ? 
      <>
        {currentPlan == "free" ? 
        <>
          <h1 className={styles.title}> Subscribe to CounterIt</h1>
          <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.planSelection}>
          <label>
            <input type="radio" value="starter" checked={selectedPlan === "starter"} onChange={() => setSelectedPlan("starter")}/> 
            Starter - $8/mo
            <p className={styles.planDescription}>25 credits / month</p>
          </label>
          <label>
            <input type="radio" value="pro" checked={selectedPlan === "pro"} onChange={() => setSelectedPlan("pro")}/> 
            Pro - $25/mo
            <p className={styles.planDescription}>100 credits / month</p>
          </label>
          <label>
            <input type="radio" value="premium" checked={selectedPlan === "premium"} onChange={() => setSelectedPlan("premium")}/> 
            Premium - $60/mo
            <p className={styles.planDescription}>250 credits / month</p>
          </label>
          </div>
          <CardElement className={styles.cardElement} />
          <button type="submit" disabled={!stripe} className={styles.payButton}>Pay</button>
          </form>
          <h3 className={styles.errorContainer}>{error ? "Error: " + error : ""}</h3>
          <h3 className={styles.goodContainer}>{goodMessage ? goodMessage : ""}</h3>
        </> : 
        <>
          <div className={styles.buttonContainer}>
          <h1>Current Subscription: {currentPlan ? currentPlan.charAt(0).toUpperCase() + currentPlan.slice(1) : ""}</h1>
          </div>
            <div className={styles.buttonContainer}>
            <h3>View the <a href="\billing">billing</a> page to change subscription</h3>
          </div>
        </>
        }
      </> 
      : 
      <>
        <h2 className={styles.notSignedIn}>Please <a href="/signin">sign in</a> or <a href="/signup">sign up</a> to view page</h2>
      </>}


    </>

  );
};

export default Payment;
