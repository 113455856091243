import React, { useState } from "react";
import "../assets/styles/Contact.css";

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = { name, email, message };

        try {
            const response = await fetch('https://counterit-backend.onrender.com/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const jsonResponse = await response.json();
            console.log('Response:', jsonResponse);
            setName("");
            setEmail("");
            setMessage("");
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    }

    return (
        <>
        <header>
            <h1 className="page-title">Contact Us</h1>
        </header>
        <main>
            <form onSubmit={handleSubmit} className="contact-form">
                <label>
                    Name:
                    <input type="text" value={name} onChange={e => setName(e.target.value)} required />
                </label>
                <label>
                    Email:
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                </label>
                <label>
                    Message:
                    <textarea value={message} onChange={e => setMessage(e.target.value)} required />
                </label>
                <input type="submit" value="Submit" />
            </form>
        </main>
        </>
    );
};

export default Contact;
