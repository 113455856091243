import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import styles from '../assets/styles/Signup.module.css';
import "../assets/styles/ResetPassword.css"

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const auth = getAuth();

  const resetPassword = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setIsLoading(false);
      setMessage('Password reset link has been sent to your email.');
      setError("")
    } catch (error) {
        console.log(error.message)
      setError("Invalid Email");
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.container}>
      <h2>Reset Password</h2>
      {message && <p className={styles.success}>{message}</p>}
      {error && <p className={styles.error}>{error}</p>}
      {isLoading ? (
        <div className={styles.ellipsis}><div></div><div></div><div></div><div></div></div>
      ) : (
        <form onSubmit={resetPassword}>
          <div className={styles.signUpItem}>
            <label>
              Email:
            </label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <button type="submit">Send Reset Link</button>
        </form>
      )}
      <p>Remembered? <a href="/signin">Sign in</a> here.</p>
    </div>
  );
}

export default ResetPassword;
