import React, { useEffect, useState, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import styles from '../assets/styles/Signup.module.css';
import { UserContext } from '../App';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {user} = useContext(UserContext);

  const auth = getAuth();

  useEffect( () => {
    if (auth.currentUser) {
        window.location.href = "/generate";
    }
  }, [auth])

  useEffect( () => {
    if (user) {
        window.location.href = "/generate";
    }
  }, [user])

  const signIn = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsLoading(false);
      window.location.href = "/generate";
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.container}>
      <h2>Sign In</h2>
      {error && <p className={styles.error}>{error}</p>}
      {isLoading ? (
        <div className={styles.ellipsis}><div></div><div></div><div></div><div></div></div>
      ) : (
        <form onSubmit={signIn}>
          <div className={styles.signUpItem}>
            <label>
              Email:
            </label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className={styles.signUpItem}>
            <label>
              Password:
            </label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <a href="/reset-password"style={{"fontSize": "14px", "marginBottom":"6%"}}>Forgot Password?</a>
          <button type="submit">Sign In</button>
        </form>
      )}

      <p>Don't have an account? <a href="/signup">Sign up</a> here.</p>
    </div>
  );
}

export default SignIn;
