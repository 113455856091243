import React, { useState, useContext, useEffect } from 'react';
import {CardElement, Elements, useStripe, useElements} from '@stripe/react-stripe-js';
import {UserContext} from '../App';
import {loadStripe} from '@stripe/stripe-js';
import styles from '../assets/styles/Billing.module.css'

function Billing() {
    const [plan, setPlan] = useState(null)
    const [error, setError] = useState(null)
    const [goodMessage, setGoodMessage] = useState(null)
    const [errorCancel, setErrorCancel] = useState(null)
    const [goodMessageCancel, setGoodMessageCancel] = useState(null)
    const stripe = useStripe();
    const elements = useElements();
    const [selectedPlan, setSelectedPlan] = useState(null)
    const {user} = useContext(UserContext)
    const tiers = ["free", "starter", "pro", "premium"]


    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null)
        setGoodMessage(null)
        const userConfirmed = window.confirm("Are you sure you want to make this payment?");
        if (!userConfirmed) {
          return;
        }
        if (!stripe || !elements) {
          return;
        }

        if (selectedPlan === plan) {
            setError("Need to select a different plan than current one")
        }
        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
          console.log(result.error.message);
          setError(result.error.message)
        } else {
          console.log(result.token);
          // You can now send the token to your backend for further processing
          fetch("https://counterit-backend.onrender.com/payment", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({token: result.token.id, plan: selectedPlan}) 
          }).then(response => response.json())
          .then(data => {
            if (data.error) {
              console.log("Something went wrong:", data.error);
            } else {
              setGoodMessage("Subscription Changed! (If downgraded, credits will reset next billing period)")
              fetch("https://counterit-backend.onrender.com/update-plan", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  plan: selectedPlan,
                  token: user.authToken
                }) 
              })
            }
          });
        }
      };


    const cancelSubscription = async (event) => {
        event.preventDefault();
        setErrorCancel(null)
        setGoodMessageCancel(null)
        let oldTier = localStorage.getItem('profile_tier')
        if (oldTier === "free") {
            setErrorCancel("No Subscription")
            return
        }

        const userConfirmed = window.confirm("Are you sure you want to cancel your subscription?");
        if (!userConfirmed) {
          return;
        }
        fetch("https://counterit-backend.onrender.com/cancel-subscription", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user_id: user.authToken
            }) 
        })
        .then(response => {
            if (response.status === 200) {
                setGoodMessageCancel("Subscription Cancelled. Credits will reset after billing period.")
            } else {
                let data = response.json()
                setErrorCancel(data.error)
            }
        })
      };
      
    useEffect(() => {
        if (user) {
            const savedProfileTier = localStorage.getItem('profile_tier')
            setPlan(savedProfileTier)
          
            fetch("https://counterit-backend.onrender.com/details", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({token: user.authToken})
            }).then(response => response.json())
            .then(data => {
                let profileTier = data['profile_tier']
                if (profileTier !== savedProfileTier) {
                  setPlan(profileTier)
                  localStorage.setItem('profile_tier', profileTier)
                }
            });
        }
      }, [user])
    
    return (
        <>
          {user ? 
          <>
              {plan !== "free" ? 
                <>
                    <div className={styles.billingContainer}>
                        <h1>Change Subscription</h1>
                    </div>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <div className={styles.planSelection}>
                            <label>
                                <input type="radio" value="starter" checked={selectedPlan === "starter"} onChange={() => setSelectedPlan("starter")}/> 
                                Starter - $8/mo
                                <p className={styles.planDescription}>25 credits / month</p>
                            </label>
                            <label>
                                <input type="radio" value="pro" checked={selectedPlan === "pro"} onChange={() => setSelectedPlan("pro")}/> 
                                Pro - $25/mo
                                <p className={styles.planDescription}>100 credits / month</p>
                            </label>
                            <label>
                                <input type="radio" value="premium" checked={selectedPlan === "premium"} onChange={() => setSelectedPlan("premium")}/> 
                                Premium - $60/mo
                                <p className={styles.planDescription}>250 credits / month</p>
                            </label>
                        </div>
                        <CardElement className={styles.cardElement} />
                        <button type="submit" disabled={!stripe} className={styles.payButton}>Pay</button>
                    </form>
                    <h3 className={styles.errorContainer}>{error ? "Error: " + error : ""}</h3>
                    <h3 className={styles.goodContainer}>{goodMessage ? goodMessage : ""}</h3>
                    <div className={styles.billingContainer}>
                        <h1>Cancel Subscription</h1>
                    </div>
                    <div className={styles.billingContainer}>
                        <h3>Current credits will remain till end of billing period</h3>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button onClick={cancelSubscription}className={styles.cancelButton}>Cancel</button>
                    </div>
                    <h3 className={styles.errorContainer}>{errorCancel ? "Error: " + errorCancel : ""}</h3>
                    <h3 className={styles.goodContainer}>{goodMessageCancel ? goodMessageCancel : ""}</h3>
                </> 
                : 
                <>
                    <div className={styles.billingContainer}>
                        <h1>Current Tier: Free </h1>
                    </div>
                    <div className={styles.billingContainer}>
                        <h1><a href="\subscribe">Subscribe</a> to view billing information.</h1>
                    </div>

                </>
            }
          </> : 
          <>
            <h2 className={styles.notSignedIn}>Please <a href="/signin">sign in</a> or <a href="/signup">sign up</a> to view page</h2>
          </>}
        </>
    );
  }
  
export default Billing;